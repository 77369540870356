import { urlsWrapper } from 'config';
import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { getAdvisorCustomerId, getCustomerId } from 'utils';
import moment from 'moment';
import { commonGet } from './api';
import { createCommonListConstants } from './utils';
import { machineTypes } from './machines';
import { getFilteredControlWorksSelector } from './controlWorkrecords';

const itemConstants = {
  ...createCommonListConstants('GPS_ITEM'),
  SET_SEARCH: 'MACHINES_ITEM_SET_SEARCH',
  SET_DIFF_TIME: 'MACHINES_SET_DIFF_TIME',
  SET_IS_OPEN_FILTERS: 'MACHINES_ITEM_IS_OPEN_FILTERS',
  SET_IS_CURRENT_DAY_FILTER: 'MACHINES_ITEM_SET_IS_CURRENT_DAY_FILTER',
  SET_TYPE_FILTER: 'MACHINES_ITEM_SET_TYPE_FILTER',
  SET_MACHINE_FILTER: 'MACHINES_ITEM_SET_MACHINE_FILTER',
  SET_IS_ACTIVE_NOW_FILTER: 'MACHINES_ITEM_SET_IS_ACTIVE_NOW_FILTER',
  SET_IS_HIDE_UNKNOWN_MACHINE_FILTER: 'SET_IS_HIDE_UNKNOWN_MACHINE_FILTER',
  CLEAR_ALL: 'MACHINES_ITEM_SET_CLEAR_ALL',
};

// reducer
export const gpsItems = (
  state = {
    list: [],
    isPending: false,
    searchText: '',
    isCurrentDay: false,
    isActiveNow: false,
    isHideUnknownMachine: false,
    isOpenFilters: false,
    type: '',
    machine: '',
    diffTime: [moment().startOf('day'), moment().endOf('day')],
  },
  action
) => {
  switch (action.type) {
    case itemConstants.SET_SEARCH:
      return {
        ...state,
        searchText: action.payload,
      };
    case itemConstants.SET_DIFF_TIME:
      return {
        ...state,
        diffTime: action.payload,
      };
    case itemConstants.SET_LIST:
      return {
        ...state,
        list: action.payload,
        isPending: false,
      };
    case itemConstants.CLEAR_ALL:
      return {
        ...state,
        isCurrentDay: false,
        isActiveNow: false,
        isOpenFilters: false,
        type: '',
      };
    case itemConstants.SET_IS_CURRENT_DAY_FILTER:
      return {
        ...state,
        isCurrentDay: action.payload,
      };
    case itemConstants.SET_IS_ACTIVE_NOW_FILTER:
      return {
        ...state,
        isActiveNow: action.payload,
      };
    case itemConstants.SET_IS_HIDE_UNKNOWN_MACHINE_FILTER:
      return {
        ...state,
        isHideUnknownMachine: action.payload,
      };
    case itemConstants.SET_IS_OPEN_FILTERS:
      return {
        ...state,
        isOpenFilters: action.payload,
      };
    case itemConstants.SET_TYPE_FILTER:
      return {
        ...state,
        type: action.payload,
      };
    case itemConstants.SET_MACHINE_FILTER:
      return {
        ...state,
        machine: action.payload,
        ...(action.payload
          ? {}
          : { diffTime: [moment().startOf('day'), moment().endOf('day')] }),
      };
    case itemConstants.SET_IS_PENDING:
      return { ...state, isPending: action.payload };
    default:
      return state;
  }
};

export function getGeoData() {
  return commonGet({
    url: urlsWrapper('geoTrackers'),
  });
}

export function getGeoStats() {
  return commonGet({ url: urlsWrapper('geoStats') });
}

export const setSearchText = (payload) => ({
  type: itemConstants.SET_SEARCH,
  payload,
});

export const setClearAll = (payload) => ({
  type: itemConstants.CLEAR_ALL,
  payload,
});

export const setTypeFilter = (payload) => ({
  type: itemConstants.SET_TYPE_FILTER,
  payload,
});

export const setMachineFilter = (payload) => ({
  type: itemConstants.SET_MACHINE_FILTER,
  payload,
});

export const setDiffTimeFilter = (payload) => ({
  type: itemConstants.SET_DIFF_TIME,
  payload,
});

export const setIsCurrentDayFilter = (payload) => ({
  type: itemConstants.SET_IS_CURRENT_DAY_FILTER,
  payload,
});

export const setIsActiveNowFilter = (payload) => ({
  type: itemConstants.SET_IS_ACTIVE_NOW_FILTER,
  payload,
});

export const setIsHideUnknownMachineFilter = (payload) => ({
  type: itemConstants.SET_IS_HIDE_UNKNOWN_MACHINE_FILTER,
  payload,
});

export const setToggleFilters = (payload) => ({
  type: itemConstants.SET_IS_OPEN_FILTERS,
  payload,
});

export const fetchGeoData = () => async (dispatch) => {
  try {
    dispatch(createAction(itemConstants.SET_IS_PENDING)(true));
    const response = await getGeoData();
    const items = (response.data?.data || []).map((item) => {
      if (item.dt && moment(item.dt).isBefore(moment().add(-5, 'minute'))) {
        return {
          ...item,
          speed: 0,
          course: 0,
        };
      }
      return item;
    });
    dispatch(createAction(itemConstants.SET_LIST)(items));
  } finally {
    dispatch(createAction(itemConstants.SET_IS_PENDING)(false));
  }
};

export const clearGeoData = () => async (dispatch) => {
  dispatch(createAction(itemConstants.SET_LIST)([]));
};

// selectors
export const getSearchTextSelector = createSelector(
  [(state) => state.gpsItems.searchText],
  (searchText) => searchText
);

export const getFilterIsCurrentDaySelector = createSelector(
  [(state) => state.gpsItems.isCurrentDay],
  (isCurrentDay) => isCurrentDay
);

export const getFilterIsActiveNowSelector = createSelector(
  [(state) => state.gpsItems.isActiveNow],
  (isActiveNow) => isActiveNow
);

export const getFilterIsHideUnknownMachineSelector = createSelector(
  [(state) => state.gpsItems.isHideUnknownMachine],
  (isHideUnknownMachine) => isHideUnknownMachine
);

export const getFilterMachineSelector = createSelector(
  [(state) => state.gpsItems.machine],
  (machine) => machine
);

export const getFilterIsOpenFiltersSelector = createSelector(
  [(state) => state.gpsItems.isOpenFilters],
  (isOpenFilters) => isOpenFilters
);

export const getFilterTypeSelector = createSelector(
  [(state) => state.gpsItems.type],
  (type) => type
);

export const getGeoDataSelector = createSelector(
  [(state) => state.gpsItems.list, (state) => state.gpsItems.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const getDiffTimeSelector = createSelector(
  [(state) => state.gpsItems.diffTime],
  (diffTimeItems) => diffTimeItems
);

export const getResultFilteredGeoData = createSelector(
  [
    (state) => state.gpsItems.list,
    (state) => state.machines.item,
    (state) => state.gpsItems.searchText,
    (state) => state.gpsItems.type,
    (state) => state.gpsItems.isCurrentDay,
    (state) => state.gpsItems.isActiveNow,
    (state) => state.gpsItems.isHideUnknownMachine,
    (state) => state.gpsItems.diffTime,
    (state) => state.gpsItems.machine,
  ],
  (
    list,
    machinesDirty,
    searchText,
    type,
    isCurrentDay,
    isActiveNow,
    isHideUnknownMachine,
    diffTime,
    machineFilter
  ) => {
    const customerId = getAdvisorCustomerId() || getCustomerId();
    const machinesDirtyList = machinesDirty[`${customerId}_clear`] || [];
    const machines = machinesDirtyList
      .filter((element) => !!element.tracker_serial)
      .reduce((resultObj, element) => {
        // eslint-disable-next-line no-param-reassign
        resultObj[element.tracker_serial] = {
          ...element,
          machineTypeResult: element.machine_type?.toLowerCase() || '',
        };

        return resultObj;
      }, {});

    const resultList = (list || [])
      .filter((item) => {
        const machine = machines?.[item.serial];
        if (isHideUnknownMachine && !machine) return false;
        const iconType = machine ? machine?.machineTypeResult : null;
        return searchText
          ? (iconType
              ? `${machineTypes[iconType.toUpperCase()]} ${
                  machine.model || ''
                }  ${machine.number || ''}`
              : `Трекер ${item.serial || ''}`
            )
              ?.toLowerCase()
              .includes(searchText?.toLowerCase())
          : true;
      })
      .filter((item) => {
        return machineFilter ? item.serial === machineFilter : true;
      })
      .filter((item) => {
        const machine = machines?.[item.serial];
        const iconType = machine ? machine?.machineTypeResult : null;
        return type ? iconType?.toUpperCase() === type?.id : true;
      })

      .filter((item) => {
        return isCurrentDay ? moment(item.dt).isSame(moment(), 'day') : true;
      })
      .filter((item) => {
        return isActiveNow
          ? moment(item.dt).isAfter(moment().add(-5, 'minutes')) && item.speed
          : true;
      })
      .map((item) => ({
        ...item,
        number: machines?.[item.serial]?.number,
        model: machines?.[item.serial]?.model,
        iconType: machines?.[item.serial]?.machineTypeResult || '',
        id: machines?.[item.serial]?.id,
      }));

    return resultList;
  }
);

export const getResultFilteredGeoDataByControlWorks = createSelector(
  [getResultFilteredGeoData, getFilteredControlWorksSelector],
  (geoData, works) => {
    const machines = works.reduce((acc, work) => {
      if (work?.machinery?.length) {
        work.machinery.forEach(({ machine_id }) => {
          if (machine_id) {
            acc.add(machine_id);
          }
        });
      }
      return acc;
    }, new Set());
    return geoData.map((geo) => {
      return {
        ...geo,
        noControlWorks: !machines.has(geo.id),
      };
    });
  }
);

export const types = {
  TODAY: 'TODAY',
  LAST_DAY: 'LAST_DAY',
  PERIOD: 'PERIOD',
};

export const getTimeIntervalByType = (type) => {
  let timeStart;
  let timeEnd;
  switch (type) {
    case types.TODAY:
      timeStart = moment().startOf('day');
      timeEnd = moment().endOf('day');
      break;
    case types.LAST_DAY:
      timeStart = moment().add(-24, 'hour');
      timeEnd = moment();
      break;
    default:
      break;
  }
  return [timeStart, timeEnd];
};
